<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: "App",
    beforeCreate() {},
    mounted() {
        this.ifPc();
    },

    methods: {
        ifPc() {
            if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                if (window.location.origin === "http://localhost:8080") {
                    window.location.href = `http://localhost:9001/index.html`;
                } else {
                    window.location.href = `${process.env.VUE_APP_CONTEXT_PATH}mobile/index.html`;
                }
            }
        },
    },
};
</script>

<style lang="less">
@import "assets/style/index.less";
</style>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fadeInUp-enter-active {
    transition: opacity 2s, transform 0.5s ease;
}
.fadeInUp-leave-active {
    transition: opacity 0.5s, transform 0.5s ease;
}
.fadeInUp-enter,
.fadeInUp-leave-to {
    opacity: 0;
    transform: translate(0, 100%) scale(0.5) !important;
}

@keyframes fade_in_up {
    0% {
        opacity: 0;
        transform: translate3d(0, 80px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
</style>
