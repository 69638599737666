export default {
    onload_prompt: "CLICK TO ENTER",
    header_nav_home: "HOME",
    header_nav_tree: "EXPERIENCE",
    header_nav_conference: "PREVIOUS",
    language: "中",
    mouse: "ROLLING THE MOUSE",

    intoTree: "Click to learn more",
    treeTitle: "About the Experience",
    scanTitle: "Scan to start",
    treeHouse:"Sci-Tech Hall for Human Tech Tree"
};
