export default {
    onload_prompt: "点击屏幕进入",
    header_nav_home: "首页",
    header_nav_tree: "科技树",
    header_nav_conference: "往届回顾",
    language: "En",
    mouse: "滚动鼠标",
    intoTree: "点击了解科技树体验",
    treeTitle: "科技树介绍",
    scanTitle: "扫码体验",
    treeHouse:"科技馆科技树"
};
