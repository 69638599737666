<template>
    <div class="home-view">
        <CustomCursor />
        <Loading
            @into="intoScene"
            v-if="loading"
            class="animate__animated animate__faster"
            :class="{ animate__fadeIn: showLoadingAnimate === 'fadeIn', animate__fadeOut: showLoadingAnimate === 'fadeOut' }"
        />

        <div class="main" v-if="!loading">
            <Header :active.sync="active" class="animate__animated animate__faster animate__fadeInDown" />
            <Scene1
                v-if="active === 0"
                @wheelNext="wheelNextPage(0)"
                class="animate__animated animate__faster"
                :class="{ animate__fadeIn: showSceneAnimate === 0, animate__fadeOut: showSceneAnimate !== 0 }"
            />
            <Scene2
                v-if="active === 1"
                @next="wheelNextPage(1)"
                @wheelLast="wheelLastPage(1)"
                class="animate__animated animate__faster"
                :class="{ animate__fadeIn: showSceneAnimate === 1, animate__fadeOut: showSceneAnimate !== 1 }"
            />
            <Scene3
                v-if="active === 2"
                @wheelNext="wheelNextPage(2)"
                @wheelLast="wheelLastPage(2)"
                class="animate__animated animate__faster"
                :class="{ animate__fadeIn: showSceneAnimate === 2, animate__fadeOut: showSceneAnimate !== 2 }"
            />
            <Scene4
                v-if="active === 3"
                @wheelNext="wheelNextPage(3)"
                @wheelLast="wheelLastPage(3)"
                class="animate__animated animate__faster"
                :class="{ animate__fadeIn: showSceneAnimate === 3, animate__fadeOut: showSceneAnimate !== 3 }"
            />
        </div>
        <portal-target name="scene-dialog"> </portal-target>
    </div>
</template>

<script>
import CustomCursor from "@/components/CustomCursor.vue";
import Loading from "@/components/Loading.vue";
import Header from "@/components/Header.vue";
import Scene1 from "@/components/scene/scene1/Scene1.vue";
import Scene2 from "@/components/scene/scene2/Scene2.vue";
import Scene3 from "@/components/scene/scene3/Scene3.vue";
import Scene4 from "@/components/scene/scene4/Scene4.vue";

export default {
    name: "Home",
    components: {
        CustomCursor,
        Loading,
        Header,
        Scene1,
        Scene2,
        Scene3,
        Scene4,
    },
    
    data() {
        return {
            loading: true,
            showLoadingAnimate: "fadeIn",
            active: 0,
            showSceneAnimate: 0,
            control: "",
        };
    },

    watch: {
        "active": {
            handler(newVal, oldVal) {
               this.showSceneAnimate = newVal
            },
            immediate: true,
        },
    },


    created() {
        this.initLanguage();

        if (process.env.NODE_ENV === "development") {
            this.loading = false;
            this.active = 1;
        }
        if (this.$route.query.active) {
            const active = Number(this.$route.query.active);
            this.active = active;
        }
    },

    methods: {
        initLanguage() {
            const locale = window.localStorage.getItem("locale") || "zh";
            this.$i18n.locale = locale;
            window.localStorage.setItem("locale", locale);
        },

        intoScene() {
            this.showLoadingAnimate = "fadeOut";
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },

        wheelNextPage: _.throttle(
            function (index) {
                this.control = "next";
                this.showSceneAnimate = index + 1;
                setTimeout(() => {
                    this.active = index + 1;
                }, 500);
            },
            1000,
            { trailing: false }
        ),
        wheelLastPage: _.throttle(
            function (index) {
                this.control = "last";
                this.showSceneAnimate = index - 1;
                setTimeout(() => {
                    this.active = index - 1;
                }, 500);
            },
            1000,
            { trailing: false }
        ),
    },
};
</script>

<style lang="less" scoped>
.home-view {
    overflow: hidden;
    .main {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
</style>
