// i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "./messages/zh";
import en from "./messages/en";

// 定义语言包
const messages = {
    zh,
    en,
};

// 设置当前语言为中文

Vue.use(VueI18n);

export default new VueI18n({
    locale: "zh", // 设置当前语言
    messages, // 设置语言包
});
