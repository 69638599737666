<!--
 * @Author: PC
 * @Date: 2024-10-09 17:49:48
 * @LastEditTime: 2024-10-29 11:12:53
 * @Description: loading组件
-->
<template>
    <div class="loading-component" @click="handleInto">
        <div class="loading-component-container">
            <div class="text-container">
                <div class="text loading-text" v-if="loading">
                    <span class="loading-text-words">L</span>
                    <span class="loading-text-words">O</span>
                    <span class="loading-text-words">A</span>
                    <span class="loading-text-words">D</span>
                    <span class="loading-text-words">I</span>
                    <span class="loading-text-words">N</span>
                    <span class="loading-text-words">G</span>
                </div>
                <!-- <div class="text ready-text" data-hover="true" v-else>
                    {{ $t("onload_prompt") }}
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/utils/loader.js";
import preloadList from "@/utils/preload-list.js";

export default {
    name: "Loading",
    data() {
        return {
            loading: true,
        };
    },

    created() {
        this.loadData();
    },

    methods: {
        async loadData() {
            const startTime = Date.now();

            const loader = new Loader();
            await loader.load(preloadList.map((url) => ({ url })));
            const endTime = Date.now();
            const elapsedTime = endTime - startTime;
            const time = 2500;
            const delay = elapsedTime >= time ? 0 : time - elapsedTime;
            setTimeout(() => {
                this.loading = false;
                this.$emit("into");
            }, delay);
        },
        handleInto() {
            if (this.loading) return;
            this.$emit("into");
        },
    },
};
</script>
<style lang="less" scoped>
.loading-component {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #000;
    transition: opacity 1s;

    &-container {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
    }

    &.hide {
        opacity: 0;
    }

    .text-container {
        position: relative;
        height: 30px;
        .text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: opacity 2s;
        }
    }
    .loading-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 30px;
        margin: auto;
        text-align: center;
        width: 100%;
        span {
            color: #fff;
            display: inline-block;
            margin: 0 5px;
            width: 20px;
            height: 20px;
            font-size: 0;
            background-size: 100% 100%;

            &:nth-child(1) {
                background-image: url(~@/assets/images/loading/L.png);
                animation: blur-text 1.5s 0s infinite linear alternate;
                filter: blur(0px);
            }
            &:nth-child(2) {
                background-image: url(~@/assets/images/loading/O.png);
                animation: blur-text 1.5s 0.2s infinite linear alternate;
                filter: blur(0px);
            }
            &:nth-child(3) {
                background-image: url(~@/assets/images/loading/A.png);
                animation: blur-text 1.5s 0.4s infinite linear alternate;
                filter: blur(0px);
            }
            &:nth-child(4) {
                background-image: url(~@/assets/images/loading/D.png);
                animation: blur-text 1.5s 0.6s infinite linear alternate;
                filter: blur(0px);
            }
            &:nth-child(5) {
                background-image: url(~@/assets/images/loading/I.png);
                animation: blur-text 1.5s 0.8s infinite linear alternate;
                filter: blur(0px);
            }
            &:nth-child(6) {
                background-image: url(~@/assets/images/loading/N.png);
                animation: blur-text 1.5s 1s infinite linear alternate;
                filter: blur(0px);
            }
            &:nth-child(7) {
                background-image: url(~@/assets/images/loading/G.png);
                animation: blur-text 1.5s 1.2s infinite linear alternate;
                filter: blur(0px);
            }
        }
    }

    .ready-text {
        color: #fff;
        font-size: 20px;
        animation: opacity-breathe-0-1 1.5s infinite alternate linear;
    }
    @keyframes blur-text {
        0% {
            filter: blur(0px);
        }
        100% {
            filter: blur(4px);
        }
    }
}
</style>
