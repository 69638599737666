import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import PortalVue from 'portal-vue';
import i18n from "./i18n";
import "animate.css";

Vue.use(PortalVue);
Vue.config.productionTip = false;
Vue.prototype.$VUE_APP_TREE_SHOW = process.env.VUE_APP_TREE_SHOW
new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
