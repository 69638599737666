<template>
    <div
        class="frame-animation"
        :style="{
            'animation-name': animationName,
            'animation-duration': `${duration}s`,
            'animation-play-state': isPlaying ? 'running' : 'paused',
            'animation-direction': isReversed ? 'reverse' : 'normal',
            width: width,
            height: height,
        }"
    >
    </div>
</template>

<script>
export default {
    name: "FrameAnimation",
    props: {
        // 图片列表
        frames: {
            type: Array,
            required: true,
        },
        // 动画总时长（秒）
        duration: {
            type: Number,
            default: 1,
        },
        // 每张图片的宽度
        width: {
            type: String,
            default: "100px",
        },
        // 每张图片的高度
        height: {
            type: String,
            default: "100px",
        },
        // 动画名称
        animationName: {
            type: String,
            required: true,
        },
        // 控制动画播放状态
        isPlaying: {
            type: Boolean,
            default: true,
        },
        // 控制动画正向或反向播放
        isReversed: {
            type: Boolean,
            default: false, // 默认正向播放
        },
    },
    computed: {
        frameCount() {
            return this.frames.length;
        },
        frameDelay() {
            return (this.duration / this.frameCount) * 1000;
        },
        animationKeyframes() {
            let keyframes = "";
            const step = 100 / this.frameCount;
            for (let i = 0; i <= this.frameCount; i++) {
                keyframes += `${i * step}% { background-image: url(${
                    this.frames[i < this.frameCount ? i : 0]
                }); }\n`;
            }
            return keyframes;
        },
    },
    mounted() {
        this.addKeyframes();
    },
    methods: {
        addKeyframes() {
            const styleSheet = document.createElement("style");
            styleSheet.type = "text/css";
            styleSheet.innerText = `@keyframes ${this.animationName} { ${this.animationKeyframes} }`;
            document.head.appendChild(styleSheet);
        },
    },
};
</script>

<style lang="less" scoped>
.frame-animation {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transition: background-position 0.5s;
    animation-play-state: paused;
}
</style>
