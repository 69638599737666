<!--
 * @Author: PC
 * @Date: 2024-10-18 11:23:04
 * @LastEditTime: 2024-11-01 14:49:49
 * @Description: 场景3组件
-->
<template>
    <div class="scene3">
        <div class="scene3_inner">
            <div class="records">
                <div v-for="(item, index) in records" :key="item.id" class="records_item">
                    <div class="info" :style="getInfoStyle(index)">
                        <div class="year">
                            <div class="gradient_text">·{{ item.year }}</div>
                        </div>
                        <div class="label gradient_text" :class="{ en_text: $i18n.locale == 'en' }" v-html="item.label?.[$i18n.locale]">
                        </div>
                        <div class="introduction" :class="{ en_text: $i18n.locale == 'en' }" v-html="item.introduction?.[$i18n.locale]"></div>
                    </div>
                    <div class="banner">
                        <img :src="item.banner" class="icon" :style="getBannerStyle(index)" />
                        <img src="@/assets/images/home/scene3/banner_bg.png" class="icon_bg" />
                    </div>
                </div>
            </div>

            <div class="timeList-box" :style="getTimeLineStyle()">
                <div class="time_line-item dot" v-for="(item, index) in records" :key="item.id" :class="{ 'active-time_line': isActive(index) }" :style="{ ...item.style }">
                    <div class="time_line-item_inner">
                        <div class="gradient_text label"  :class="{ en_text: $i18n.locale == 'en' }">{{ item.year }}·{{ item.label?.[$i18n.locale] }}</div>
                    </div>
                </div>
                <div></div>

                <div class="time_line-item_last dot">
                    <div class="gradient_text tree_text" :class="{ en_text: $i18n.locale == 'en' }">2028·{{ $t("treeHouse") }}</div>
                    <div class="scene3-item_lost_img">
                        <img src="@/assets/images/home/scene3/timeList_icon.png" class="icon" />
                    </div>
                </div>
            </div>
            <canvas class="time_line_progress" ref="myCanvas"></canvas>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "Scene3",
    data() {
        return {
            position: 0,
            records: [
                {
                    id: 0,
                    banner: require("@/assets/images/home/scene3/banner0.png"),
                    timeLineImg: require("@/assets/images/home/scene3/timeLine_img0.png"),
                    year: "2013",
                    label: {
                        zh: "第一届WE大会",
                        en: "The first WE Summit",
                    },
                    introduction: {
                        zh: "WE大会开始了对互联网技术新生态、新形态及其对各行各业的影响的前瞻探索，并由此延伸到对科学以及人类未来命运的根本思考。",
                        en: "The WE Summit explores fascinating topics such as emerging ecology, forms of Internet technology, and their impact on our lives, offering a great opportunity to rethink about science and the future of humanity.",
                    },
                    style: { left: "13%", bottom: "27%" },
                },
                {
                    id: 1,
                    banner: require("@/assets/images/home/scene3/banner1.png"),
                    timeLineImg: require("@/assets/images/home/scene3/timeLine_img1.png"),
                    year: "2022",
                    label: {
                        zh: "WE大会科技树概念发布",
                        en: "The concept of the “Human Tech Tree” was introduced at WE Summit",
                    },
                    introduction: {
                        zh: "腾讯提出了“科技树”的新概念,腾讯持续助力基础科学的发展。",
                        en: "By introducing the new concept the “Human Tech Tree”, Tencent continues to facilitate the development of basic science.",
                    },
                    style: { left: "38%", bottom: "13%" },
                },
                {
                    id: 2,
                    banner: require("@/assets/images/home/scene3/banner2.png"),
                    timeLineImg: require("@/assets/images/home/scene3/timeLine_img2.png"),
                    year: "2024",

                    label: {
                        zh: "WE大会体验雏形",
                        en: "WE Summit Experience",
                    },
                    introduction: {
                        zh: "今年，全新科技树体验上线，让用户以全新的互动方式直观探索科技的关键转折点。",
                        en: `The new “Human Tech Tree" experience went live this year, allowing users to visually explore key turning points in technology.`,
                    },
                    style: { left: "64%", bottom: "13%" },
                },
            ],
            canJump: true,
            canJumpTimer: void 0,
            progress: 0.333,
            currentProgress: 0.333,
            timeLineScale: 1,
            points: {
                line1: [
                    { x: 150, y: 100 },
                    { x: 550, y: 145 },
                    { x: 1370, y: 145 },
                    { x: 1770, y: 100 },
                ],

                line2: [
                    { x: 150, y: 100 },
                    { x: 550, y: 145 },
                    { x: 1370, y: 145 },
                    { x: 1770, y: 100 },
                ],
                line3: [
                    { x: 350, y: 0 },
                    { x: 35, y: 40 },
                    { x: 35, y: 84 },
                    { x: 150, y: 100 },
                ],

                line4: [
                    { x: 1570, y: 0 },
                    { x: 1885, y: 40 },
                    { x: 1885, y: 84 },
                    { x: 1770, y: 100 },
                ],
            },
        };
    },
    mounted() {
        this.initCanvas(); // 组件挂载后初始化画布

        setTimeout(() => {
            document.addEventListener("wheel", this.throttledOnWheel);
        }, 800);
    },
    beforeDestroy() {
        // 移除事件监听
        document.removeEventListener("wheel", this.throttledOnWheel);
    },

    methods: {
        // 滚轮事件处理：模拟前进或后退
        throttledOnWheel(event) {
            const maxPosition = 0; // 限制在最前面
            const minPosition = -(this.records.length - 1 - maxPosition); // 限制在最后一张卡片的位置
            const speed = -event.deltaY / 1000;
            const position = Number((this.position + speed).toFixed(3));
            const newPosition = position < minPosition ? minPosition : position > maxPosition ? maxPosition : position;

            this.canvasThrottleFun(newPosition);

            if (this.position == newPosition) {
                this.canJumpTimer = setTimeout(() => {
                    this.canJump = true;
                }, 1000);
                if (this.canJump) {
                    if (this.position == minPosition) {
                        this.$emit("wheelNext");
                    }
                }
            } else {
                clearTimeout(this.canJumpTimer);
                this.canJump = false;
            }
            this.position = newPosition;
        },

        // 获取介绍卡片的样式
        getInfoStyle(index) {
            const xPos = (index + this.position) * 100;
            return {
                transform: `translateX(${xPos}vw)`,
            };
        },

        // 获取介绍卡片的样式
        getBannerStyle(index) {
            const xPos = Number((index + this.position).toFixed(2));
            const opacity = xPos >= -0.3 && xPos <= 0.6 ? 1 - Math.abs(xPos) : 0;
            return {
                opacity,
            };
        },

        // 获取时间线的样式
        getTimeLineStyle() {
            const totalDots = this.records.length;
            const dotPositions = this.records.map((dot, i) => (i + 1) / totalDots); // 计算点的位置
            let opacity = 1; // 默认透明度

            for (let i = 0; i < totalDots - 1; i++) {
                const lowerDot = dotPositions[i];
                const upperDot = dotPositions[i + 1];
                const median = (lowerDot + upperDot) / 2; // 中位数

                if (this.progress >= lowerDot && this.progress <= upperDot) {
                    if (this.progress < median) {
                        opacity = 1 - (this.progress - lowerDot) / (median - lowerDot); // 进度小于中位数时降低透明度
                    } else {
                        opacity = (this.progress - median) / (upperDot - median); // 进度大于中位数时增加透明度
                    }
                    break;
                }
            }

            return {
                opacity,
            };
        },

        // 当前选中的时间节点
        isActive(index) {
            const totalDots = this.records.length;
            const dotPositions = this.records.map((dot, i) => (i + 1) / totalDots); // 计算点的位置

            if (index === 0) {
                const median = (dotPositions[index] + dotPositions[index + 1]) / 2;
                return this.progress >= dotPositions[index] - 0.01 && this.progress < median;
            } else if (index === totalDots - 1) {
                const median = (dotPositions[index - 1] + dotPositions[index]) / 2;
                return this.progress >= median && this.progress <= dotPositions[index];
            } else {
                const lowerMedian = (dotPositions[index - 1] + dotPositions[index]) / 2;
                const upperMedian = (dotPositions[index] + dotPositions[index + 1]) / 2;
                return this.progress >= lowerMedian && this.progress < upperMedian;
            }
        },

        drawCurve(ctx, lineWidth, points = [], strokeStyle) {
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = strokeStyle;
            ctx.beginPath();

            ctx.moveTo(points[0].x, points[0].y); // 移动到起始点

            for (let i = 1; i < points.length - 2; i++) {
                const cp1 = points[i]; // 控制点1
                const cp2 = points[i + 1]; // 控制点2
                ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, points[i + 2].x, points[i + 2].y);
            }

            ctx.stroke(); // 绘制路径
        },

        animateProgress() {
            if (Math.abs(this.currentProgress - this.progress) < 0.001) {
                this.currentProgress = this.progress;
            } else {
                // 插值，平滑过渡
                this.currentProgress += (this.progress - this.currentProgress) * 0.002;
            }

            const canvas = this.$refs.myCanvas;
            if (!canvas) return;
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除画布
            // ctx.scale(this.timeLineScale, this.timeLineScale); // 缩放上下文

            // 背景线1
            const gradientline1 = ctx.createLinearGradient(1620, 0, 0, 0);
            gradientline1.addColorStop(0, "rgba(250, 250, 250, 0.3)");
            gradientline1.addColorStop(0.333, "rgba(59, 79, 130, 0.8)");
            gradientline1.addColorStop(1, "rgba(59, 79, 130, 1)");
            this.drawCurve(ctx, 3, this.points.line1, gradientline1);

            // 背景线2
            const gradientline4 = ctx.createLinearGradient(0, 0, 0, 100);
            gradientline4.addColorStop(0, "rgba(250, 250, 250, 0.01)");
            gradientline4.addColorStop(1, "rgba(250, 250, 250, 0.3)");
            this.drawCurve(ctx, 3, this.points.line4, gradientline4);

            // 进度条线1
            const gradientline2 = ctx.createLinearGradient(0, 0, 1620, 0);
            gradientline2.addColorStop(0, "rgba(39, 58, 106,0.1)");
            gradientline2.addColorStop(0.1, "rgba(39, 58, 106,1)");
            gradientline2.addColorStop(this.currentProgress, "#ffffff");
            gradientline2.addColorStop(this.currentProgress + 0.005 < 1 ? this.currentProgress + 0.005 : this.currentProgress, "transparent");
            gradientline2.addColorStop(1, "transparent");
            this.drawCurve(ctx, 5, this.points.line2, gradientline2);

            // 进度条线2
            const gradientline3 = ctx.createLinearGradient(0, 0, 0, 100);
            gradientline3.addColorStop(0, "rgba(39, 58, 106,0.1)");
            gradientline3.addColorStop(1, "rgba(39, 58, 106,1)");
            this.drawCurve(ctx, 5, this.points.line3, gradientline3);

            requestAnimationFrame(this.animateProgress);
        },

        // 初次绘制
        initCanvas() {
            const canvas = this.$refs.myCanvas;
            if (!canvas) return;
            canvas.width = 1920 * this.timeLineScale; // 设置画布宽度
            canvas.height = 150 * this.timeLineScale; // 设置画布高度

            this.animateProgress(); // 初次绘制
        },

        // 进度条
        canvasThrottleFun(position) {
            const progress = Math.abs(position) * 0.333 + 0.333;

            this.progress = progress;
            this.animateProgress(); // 初次绘制
        },
    },
};
</script>

<style lang="less" scoped>
.scene3 {
    width: 100%;
    height: 100vh;
    background: url("~@/assets/images/home/scene3/bg0.png") no-repeat center, url("~@/assets/images/home/scene3/bg1.png") no-repeat center;
    background-size: cover, cover;
    position: relative;
    .scene3_inner {
        .records {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 411px;
            .info {
                position: absolute;
                top: 0px;
                left: 100px;
                transform: translate3d(0, 0px, 0px);
                transition: transform 0.3s;
                z-index: 2;
                min-width: 500px;

                .year {
                    font-size: 18px;
                }
                .label {
                    max-width: 474px;
                    margin: 18px 0;
                    font-size: 40px;
                    font-weight: 600;
                }

                .introduction {
                    max-width: 420px;
                    color: #efefefcc;
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: 3.36px;
                }
            }
            .banner {
                width: 411px;
                height: 411px;
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                img {
                    width: 100%;
                    height: 100%;
                    transition: opacity 1.5s;
                }
                .icon_bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
            }
        }
        .timeList-box {
            width: 100%;
            max-width: 1920px;
            aspect-ratio: 12.8 / 1;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 56px;
            transition: opacity 1s;

            .dot {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: -21px;
                    background: rgb(173, 173, 173);
                    height: 10px;
                    width: 10px;
                    z-index: 99;
                    border-radius: 50%;
                }
            }
            .time_line-item {
                transform: translate(-50%, -50%);
                position: absolute;
                border-radius: 24px;
                background: linear-gradient(180deg, #ffffff 0%, #689aff 100%);
                padding: 1px;
                > div {
                    font-size: 12px;
                    border-radius: 24px;
                    padding: 4px 20px;
                }
            }
            .time_line-item_inner {
                transition: background 1.5s ease;
                position: relative;
                overflow: hidden;
                background: #020105;
                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    opacity: 0;
                    transition: opacity 1.5s ease;
                }
                .label {
                    max-width: 230px;
                    font-size: 16px;
                    text-align: center;
                }
                .en_text {
                    font-size: 12px;
                }
            }

            .time_line-item_last {
                position: absolute;
                left: 85%;
                bottom: 20%;

                &::after {
                    bottom: 8px;
                }
                .tree_text {
                    
                    text-align: center;
                    font-size: 16px;

                    opacity: 0.6;
                }
                .en_text {
                    max-width: 130px;
                    font-size: 12px;
                }

                .scene3-item_lost_img {
                    margin-top: -20px;
                    img {
                        width: 140px;
                    }
                }
            }
            .active-time_line {
                &::after {
                    background: rgb(255, 255, 255) !important;
                }
                .label {
                    background: transparent;
                    color: #020105;
                    -webkit-text-fill-color: #020105;
                }
                .time_line-item_inner {
                    background: transparent;
                    &::after {
                        opacity: 1;
                        background: linear-gradient(269deg, rgba(248, 244, 255, 1) 0.49%, rgba(115, 152, 255, 1) 76.38%);
                    }
                }
            }
        }

        .time_line_progress {
            position: absolute;
            bottom: 56px;
            z-index: -1;
            width: 100%;
            max-width: 1920px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
</style>
