import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
];

const router = new VueRouter({
    routes,
});

export default router;
