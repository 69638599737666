var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame-animation",style:({
        'animation-name': _vm.animationName,
        'animation-duration': `${_vm.duration}s`,
        'animation-play-state': _vm.isPlaying ? 'running' : 'paused',
        'animation-direction': _vm.isReversed ? 'reverse' : 'normal',
        width: _vm.width,
        height: _vm.height,
    })})
}
var staticRenderFns = []

export { render, staticRenderFns }