<template>
    <div class="operating_tips-component">
        <div class="animate__animated animate__fadeInUp animate__slow">
            <FrameAnimation :frames="mouse" :duration="1" width="20px" height="32px" class="icon" v-bind="$attrs" />
            <div class="text">{{ $t("mouse") }}</div>
        </div>
    </div>
</template>

<script>
import FrameAnimation from "@/components/FrameAnimation.vue";

export default {
    name: "OperatingTips",
    components: {
        FrameAnimation,
    },
    data() {
        return {
            mouse: [require("@/assets/images/home/mouse/icon_mouse0.png"), require("@/assets/images/home/mouse/icon_mouse1.png"), require("@/assets/images/home/mouse/icon_mouse2.png")],
        };
    },
};
</script>

<style lang="less" scoped>
.operating_tips-component {
    width: 200px;
    height: 70px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 998;
    .icon {
        margin: 0 auto;
    }
    .text {
        margin-top: 10px;
        text-align: center;
        
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 3.84px;
        font-weight: 300;
    }
}
</style>
