<!--
 * @Author: PC
 * @Date: 2024-10-11 15:51:45
 * @LastEditTime: 2024-11-02 18:16:59
 * @Description: 嘉宾详情弹框组件
-->
<template>
    <div class="guest_details-dialog">
        <div class="dialog_inner">
            <div class="close" data-hover="true" @click="handleClose"></div>
            <div class="dialog_content">
                <img :src="guestInfo?.card" class="avatar" />
                <img :src="guestInfo?.avatarBG" class="avatar_bg" />
                <div class="info">
                    <div>
                        <div class="label gradient_text">{{ guestInfo?.label?.[$i18n.locale] }}</div>
                    </div>
                    <div>
                        <div class="name gradient_text">{{ guestInfo?.name?.[$i18n.locale] }}</div>
                    </div>
                    <div class="title">
                        <div v-for="(item, index) in guestInfo?.title?.[$i18n.locale] || []" :key="index" class="title_i">{{ item }}</div>
                    </div>
                    <div class="main_info no_scrollbar">
                        <div class="introduction" v-html="guestInfo?.introduction?.[$i18n.locale]"></div>
                        <div v-show="guestInfo?.video?.url" class="video">
                            <div id="video">{{ guestInfo?.video?.url }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GuestDetailsDialog",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        guestInfo: {
            type: Object,
            default: {},
        },
    },

    data() {
        return {
            player: "",
        };
    },

    mounted() {
        this.initVideo();
    },

    beforeDestroy() {
        window.player && window.player.destroy && window.player.destroy();
        window.player = null
    },
    methods: {
        initVideo() {
            this.$nextTick(() => {
                if (!this.guestInfo?.video?.url) return;
                window.player = new Txplayer({
                    containerId: "video",
                    vid: this.guestInfo?.video?.url,
                    width: "100%",
                    height: 417,
                });
            });
        },

        handleClose() {
            this.$emit("update:visible", false);
        },
    },
};
</script>

<style lang="less">
.guest_details-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .dialog_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(0.7);
        transform-origin: center center;
        width: 90%;
        max-width: 1200px;

        border-radius: 24px;
        background: linear-gradient(180deg, #ffffff 0%, #689aff 100%);
        padding: 1px;
        overflow: hidden;
        @media screen and (min-width: 1200px) {
            transform: translate(-50%,-50%) scale(0.7);
        }
        @media screen and (min-width: 1366px) {
            transform: translate(-50%,-50%) scale(0.8);
        }
        @media screen and (min-width: 1920px) {
            transform: translate(-50%,-50%) scale(0.95);
        }

        .close {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 24px;
            right: 24px;
            background-image: url(~@/assets/images/home/scene2/icon_close.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 999;
            transition: all 0.3s;
            &:hover {
                opacity: 0.7;
                transform: scale(1.1);
            }
        }
        .dialog_content {
            background: #020105;
            position: relative;
            border-radius: 24px;
            padding: 40px 74px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .avatar {
                width: 40%;
                max-width: 405px;
            }
            .avatar_bg {
                position: absolute;
                top: 0px;
                right: 0px;
                max-width: 500px;
                width: 40%;
            }
            .info {
                padding: 0 20px;
                width: 60%;
                max-width: 562px;

                .label {
                    font-size: 18px;
                }
                .name {
                    font-size: 36px;
                    font-weight: 600;
                }

                .title {
                    padding-top: 12px;
                    .title_i {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }
                .main_info {
                    max-height: 400px;
                    overflow-y:auto ;
                    .introduction {
                        padding: 20px 0;
                        font-size: 16px;
                        line-height: 30px;
                        color: #fff;
                    }
                    .video {
                        width: 100%;
                        aspect-ratio: 4/3;
                    }
                }
            }
        }
    }
}
</style>
