<template>
    <div class="mouse-trail"></div>
</template>

<script>
export default {
    name: "MouseTrail",
    mounted() {
        this.initTrails();
        this.animateTrails();
    },
    data() {
        return {
            trailCount: 20,
            trails: [],
            prevMousePosition: { x: 0, y: 0 },
            mousePosition: { x: 0, y: 0 },
        };
    },
    methods: {
        initTrails() {
            for (let i = 0; i < this.trailCount; i++) {
                const trail = document.createElement("div");
                trail.classList.add("trail");
                document.body.appendChild(trail); // 插入到 body 中
                this.trails.push(trail);
            }

            document.addEventListener("mousemove", this.onMouseMove);
            document.addEventListener("mouseover", this.handleMouseOver);
            document.addEventListener("mouseout", this.handleMouseOut);
        },

        onMouseMove(event) {
            this.mousePosition.x = event.pageX;
            this.mousePosition.y = event.pageY;
        },

        animateTrails() {
            this.trails.forEach((trail, index) => {
                const x = this.prevMousePosition.x + (this.mousePosition.x - this.prevMousePosition.x) * (index / this.trailCount);
                const y = this.prevMousePosition.y + (this.mousePosition.y - this.prevMousePosition.y) * (index / this.trailCount);
                const roundSize = (1 + index / this.trailCount).toFixed(2);

                trail.style.transform = `translate(${x - 3}px, ${y - 3}px) scale(${roundSize})`;
                trail.style.opacity = ((index + 1) / this.trailCount).toFixed(2);
            });

            this.prevMousePosition.x += (this.mousePosition.x - this.prevMousePosition.x) * 0.15;
            this.prevMousePosition.y += (this.mousePosition.y - this.prevMousePosition.y) * 0.15;

            requestAnimationFrame(this.animateTrails);
        },

        handleMouseOver(e) {
            // 判断是否悬停在 cursor: pointer 的元素上
            const isPointer = e.target.dataset.hover === "true";
            this.isPointer = isPointer;
            if (isPointer) {
                this.trails[this.trails.length - 1].classList.add("ripple");
            }
        },

        handleMouseOut() {
            this.trails[this.trails.length - 1].classList.remove("ripple");
        },
    },
    beforeDestroy() {
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseover", this.handleMouseOver);
        document.removeEventListener("mouseout", this.handleMouseOut);
        this.trails.forEach((trail) => {
            document.body.removeChild(trail); // 组件销毁时移除光点
        });
    },
};
</script>

<style>
.trail {
    position: fixed;
    left: 0;
    top: 0;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.05s linear;
    z-index: 9999;
    box-shadow: 0px 0px 3px #fff;
}
.trail:last-child::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;

    border-radius: 50%;
    box-shadow: 0px 0px 3px #fff, inset 0px 0px 2px #fff;
    z-index: 10000;
    transform: scale(2.5);
    opacity: 0.3;
}

.ripple::after {
    animation: wave-animation 0.8s linear infinite;
}

@keyframes wave-animation {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    70% {
        transform: scale(4);
        opacity: 1;
    }
    100% {
        transform: scale(5);
        opacity: 0;
    }
}
</style>
