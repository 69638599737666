<!--
 * @Author: PC
 * @Date: 2024-10-18 11:35:58
 * @LastEditTime: 2024-11-01 23:50:38
 * @Description: 场景2组件
-->
<template>
    <div class="scene2">
        <div ref="scene2BG" class="page_bg">
            <div class="sequence_frame-bg"></div>
            <!-- <FrameAnimation animationName="scene2_bg" :isPlaying="isPlaying" :isReversed="isReversed" :frames="bg" :duration="4" width="100%" height="100%" class="sequence_frame-bg" :key="100" /> -->
        </div>
        <Card
            :stop="guestDetailsDialogShow"
            @start="startFrameAnimation"
            @viewDetails="handleDetails"
            @onWheel="onWheel"
            @intoNext="$emit('next')"
            class="animate__animated animate__zoomIn animate__slow"
        />
        <Portal to="scene-dialog">
            <transition name="fadeInUp">
                <GuestDetailsDialog :guestInfo="guestInfo" v-if="guestDetailsDialogShow" :visible.sync="guestDetailsDialogShow" />
            </transition>
        </Portal>
        <!-- <OperatingTips animationName="scene2_mouse" /> -->
    </div>
</template>

<script>
import FrameAnimation from "@/components/FrameAnimation.vue";
import GuestDetailsDialog from "@/components/scene/scene2/GuestDetailsDialog.vue";
import Card from "@/components/scene/scene2/Card.vue";
import OperatingTips from "@/components/OperatingTips.vue";
import { gsap } from "gsap";

export default {
    name: "Scene2",
    components: {
        FrameAnimation,
        GuestDetailsDialog,
        Card,
        OperatingTips,
    },
    data() {
        return {
            bg: [],
            showMouse: false,
            guestInfo: {},
            guestDetailsDialogShow: false,
            isPlaying: false,
            isReversed: false,
            startFrameAnimationTimer: void 0,
        };
    },

    mounted() {
        setTimeout(() => {
            document.addEventListener("mousemove", this.handleMouseMove);
        }, 2000);
    },
    beforeDestroy() {
        // 移除事件监听
        document.removeEventListener("mousemove", this.handleMouseMove);
    },

    methods: {
        onWheel(type) {
            if (type === -1) {
                this.$emit("wheelLast");
            }
        },

        startFrameAnimation(type) {
            this.isReversed = type === -1;
            this.isPlaying = true;
            clearInterval(this.startFrameAnimationTimer);
            this.startFrameAnimationTimer = setTimeout(() => {
                this.isPlaying = false;
            }, 1600);
        },

        handleMouseMove(e) {
            if (this.guestDetailsDialogShow) return;
            const x = e.clientX / window.innerWidth - 0.5;
            const y = e.clientY / window.innerHeight - 0.5;
            gsap.to(this.$refs.scene2BG, {
                x: x * 15, // 根据鼠标位置调整 x 位移
                y: y * 15, // 根据鼠标位置调整 y 位移
                duration: 0.1,
                ease: "power1.out",
            });
        },

        handleDetails(item) {
            this.guestInfo = item || {};
            this.guestDetailsDialogShow = true;
        },
    },
};
</script>

<style lang="less" scoped>
.scene2 {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .page_bg {
        position: fixed;
        top: -15px;
        left: -15px;
        right: -15px;
        bottom: -15px;

        .sequence_frame-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("~@/assets/images/home/scene3/bg1.png") no-repeat center, url("~@/assets/images/home/scene3/bg0.png") no-repeat center;
            background-size: cover, cover;
        }
    }
}
</style>
